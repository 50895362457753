.login_container {
    background: url('../assets/login.png') no-repeat;
    width: 100%;
    height: 100%;
}

.login-body {
    padding-top: 2rem;
}

.login-company {
    width: 16rem;
    height: 4rem;
    margin-left: 2rem;
}

.login-form {
    width: 28%;
    height: 22rem;
    position: absolute;
    right: 8%;
    top: 40%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);

    .login-title {
        font-size: 3rem;
        font-weight: 700;
        color: #3C4353;
        text-align: center;
        padding-bottom: 3rem;
        font-family: "思源黑体", SiYuanRegular;
    }

    p {
        margin-bottom: 6px;
        font-size: 16px;
    }

    .ant-input-affix-wrapper {
        height: 56px;
        border: none;
        background-color: rgba(24, 144, 255, 0.06) !important
    }

    .ant-input {
        background-color: rgba(24, 144, 255, 0)
    }

    .ant-input-affix-wrapper:hover {
        border: none;
    }

    .user-name {
        width: 100px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}